<template>
  <ItemForm
    v-if="!isLoading"
    :title="`Update ${config.name}`"
    :config="config"
    :submit-handler="config.updateHandler || submitHandler"
    :delete-handler="config.deleteHandler || deleteHandler"
    :initial-value="initialValue"
    :submit-message="`Updating ${itemLabel}...`"
    :submit-success-message="`Successfully updated ${itemLabel}.`"
    :delete-message="`Deleting ${itemLabel}...`"
    :delete-success-message="`Successfully deleted ${itemLabel}.`"
    v-on="$listeners"
  />
</template>

<script>
import ItemForm from './ItemForm';
import axios from '../../utils/api-axios';
import configs from './configs';
import toFormData from '../../utils/to-form-data';
import transformBeforeMounted from '../../utils/transform-before-mounted';

export default {
  components: {
    ItemForm,
  },
  data() {
    return {
      initialValue: {},
      isLoading: true,
    };
  },
  computed: {
    config() {
      return {
        ...configs[this.$route.params.type],
        ...(configs[this.$route.params.type].overrides || {}).updatePage,
      };
    },
    /**
     * @return {string}
     */
    URIEncodedId() {
      return encodeURIComponent(this.$route.params.id);
    },
    itemLabel() {
      return this.initialValue[
        this.config.labelKey || this.config.identifierKey
      ];
    },
  },
  created() {
    axios.get(this.config.apiUrl + '/' + this.URIEncodedId).then(r => {
      this.$set(
        this,
        'initialValue',
        transformBeforeMounted(r.data, this.config),
      );
      this.isLoading = false;
    });
  },
  methods: {
    submitHandler(data) {
      const requestBody = this.config.formData ? toFormData(data) : data;
      return axios.put(
        this.config.apiUrl + '/' + this.URIEncodedId,
        requestBody,
      );
    },
    deleteHandler() {
      return axios.delete(this.config.apiUrl + '/' + this.URIEncodedId);
    },
  },
};
</script>

<style lang="scss" scoped></style>
